.impactContainer {
	height: calc(100vh - 100px);
	padding-top: 80px;
}

.dashboard {
	width: 600px;
	height: 550px;
	margin: auto;
	padding: 20px;
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 20px;
	border-width: 1px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
	color: #6d6b6b;
	font-weight: 600;
}

.dashboardHeader {
	width: 100%;
	height: 100px;
}

.dashboardTitle {
	margin: 0px;
	margin-left: 12px;
	text-align: left;
	font-size: 20px;
}

.carbonFootprint {
	margin: 0px;
	margin-right: 12px;
	text-align: right;
	font-size: 20px;
}

.carbonFootprintValue {
	margin: 0px;
	text-align: right;
	font-size: 30px;
}

.carbonFootprintUnit {
	margin-right: 12px;
	font-size: 16px;
}

.emissionsTitle {
	margin: 0px;
	margin-left: 12px;
	margin-bottom: 4px;
	text-align: left;
	font-size: 16px;
}

.emissionsValue {
	font-size: 20px;
}

.impactTitle {
	margin: 0px;
	margin-top: 20px;
	margin-left: 12px;
	margin-bottom: 4px;
	text-align: left;
	font-size: 16px;
}

.impactValue {
	font-size: 20px;
	color: #74CA86;
	
}

.dashboardContent {
	width: 100%;
	height: 100px;
	padding: 10px;
	background-color:#f7f8fa;
	border-width: 1px;
	border-style: solid;
	border-color: #cacaca;
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.dashboardLeft {
  width: 30%;

}

.dashboardMiddle {
  width: 30%;

}

.dashboardRight {
  width: 30%;

}

.contentHeader{
	font-size: 15px;
	color: #6d6b6b;
	margin-top: 10px;
	margin-bottom: 12px;
} 


.dashboardSummary {
	width: 100%;
	margin-top: 16px;
	height: 120px;
	line-height: 24px;
	font-size: 15px;
	font-weight: 600;
}

.dashboardLink {
	color: #52B788;
  text-decoration: none;
}

.dashboardSpacer {
	display: none;
}

.carbonFYI {
	color: #6d6b6b;
	position: absolute;
	display: block;
	bottom: 4px;
	width: 100%;
	text-align: center;
	text-decoration: none;
}

@media only screen and (max-height: 750px) {
	.impactContainer {
		overflow-y: scroll;
	}
	
	.carbonFYI {
		position:relative;
	}

	.dashboardSpacer {
		display: block;
		height: 20vh;
	}
	
}

@media only screen and (max-width: 600px) {
	.impactContainer {
		height: calc(100vh - 100px);
		padding-top: 40px;
	}
	
	.dashboard {
		width: 300px;
		height: 360px;
		margin: auto;
		padding: 10px;
	}
	
	.dashboardHeader {
		width: 100%;
		height: 60px;
	}
	
	.dashboardTitle {
		margin: 0px;
		margin-left: 12px;
		text-align: left;
		font-size: 14px;
	}
	
	.carbonFootprint {
		margin: 0px;
		margin-right: 12px;
		text-align: right;
		font-size: 14px;
	}
	
	.carbonFootprintValue {
		margin: 0px;
		text-align: right;
		font-size: 16px;
	}
	
	.carbonFootprintUnit {
		margin-right: 12px;
		font-size: 12px;
	}
	
	.emissionsTitle {
		margin-bottom: 2px;
		font-size: 12px;
	}
	
	.emissionsValue {
		font-size: 14px;
	}
	
	.impactTitle {
		margin-top: 10px;
		margin-bottom: 2px;
		font-size: 12px;
	}
	
	.impactValue {
		font-size: 14px;
	}
	
	.dashboardContent {
		width: 100%;
		height: 60px;
		padding: 2px;
	}
	
	.dashboardLeft {
	  width: 30%;
	
	}
	
	.dashboardMiddle {
	  width: 30%;
	
	}
	
	.dashboardRight {
	  width: 30%;
	
	}
	
	.contentHeader{
		font-size: 8px;
		color: #6d6b6b;
		margin-top: 5px;
		margin-bottom: 5px;
	} 
	
	
	.dashboardSummary {
		width: 100%;
		margin-top: 10px;
		height: 120px;
		line-height: 14px;
		font-size: 10px;
		font-weight: 600;
	}
	
	.dashboardLink {
		color: #52B788;
	  text-decoration: none;
	}
	
}