* {
	box-sizing: border-box;
	font-family: 'Montserrat';
}

.plantContainer {
	height: calc(100vh - 100px);
	padding-top: 140px;
}

.plantGrid {
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 400px;
}

.plantLeft {
	height: 400px;
	width: 500px;
	background-color: rgba(255, 255, 255, 0.8);
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 200px;
	border-radius: 20px;
	border-width: 1px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
	padding: 20px;
	color: #6d6b6b;
	font-weight: 600;
}

.plantTitle {
	color: #6d6b6b;
	text-align: left;
	font-weight: 600;
	font-size: 20px;
	margin-top: 6px;
	padding-left: 10px;
}

.levelSelector {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 70px;
	margin-top: 20px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-color: #cacaca;
	border-width: 1px;
	border-style: solid;
	background-color: #f7f8fa;
}

.selector {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 25%;
	margin-left: auto;
	margin-right: auto;
}

.selectorButton {
	display: block;
	margin-top: auto;
	margin-bottom: auto;
	width: 50px;
	height: 32px;
	font-size: 20px;
	color: white;
	border-radius: 20px;
	border-style: none;
	background-color: #52B788;
	box-shadow: rgb(0 0 0 / 8%) 0px 6px 10px;
	font-weight: 600;
}

.selectorButton:hover {
	cursor:pointer;
}

.level {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 60%;
	margin-right: auto;
}

.levelText {
	font-size: 20px;
}

.levelDescription {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 0px;
	color: #6d6b6b; 
	width: 100%;
	height: 36px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	border-color: #cacaca;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-right-width: 1px;
	border-style: solid;
	border-top-style: none;
	background-color: rgba(255, 255, 255, 0.6);
}

.description {

}

.currencySelector {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 80px;
	margin-top: 28px;
	border-radius: 20px;
	border-color: #cacaca;
	border-width: 1px;
	border-style: solid;
	background-color: rgba(255, 255, 255, 0.6);

}

.currency {
	width: 25%;
	margin: auto;
}

.currencyOption {
	width: 70px;
	height: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.currencyText {
	margin: 3px;
	text-align: center;
	color: #6d6b6b;
}

.currencyLogo {
	height: 20px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 6px;
	margin-left: 10px;
}

.total {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 60%;
	margin-right: auto;
}

.totalText {
	font-size: 20px;
}

.plantButton {
	width: 100%;
	height: 60px;
	margin-top: 28px;
	border-radius: 20px;
	border-width: 1px;
	border-style: solid;
	border-color: #cacaca;
	background-color: #52B788;
	font-size: 18px;
	font-weight: 600;
	color: white;
}

.plantButton:hover {
	cursor:pointer;
}

.plantRight {
	height: 400px;
	width: 400px;
	background-color: #1D2D3A;
	margin-top: auto;
	margin-bottom: auto;
	border-radius: 20px;
}

img {
	border-radius: 20px;
}

.video-player {
	border-radius: 20px;
	overflow: hidden;
}

.Dropdown-root {
	position: relative;
}

.Dropdown-control {
	width: 128px;
	position: relative;
	overflow: hidden;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 20px;
	box-sizing: border-box;
	color: #333;
	cursor: default;
	outline: none;
	padding: 8px 52px 8px 10px;
	transition: all 200ms ease;
}

.Dropdown-control:hover {
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
	border-color: #999 transparent transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	content: ' ';
	display: block;
	height: 0;
	margin-top: -ceil(2.5);
	position: absolute;
	right: 10px;
	top: 18px;
}

.is-open .Dropdown-arrow {
	border-color: transparent transparent #999;
	border-width: 0 5px 5px;
}

.Dropdown-menu {
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: rgb(0 0 0 / 8%) 0px 6px 10px;
	box-sizing: border-box;
	margin-top: 0px;
	max-height: 200px;
	overflow-y: auto;
	position: absolute;
	top: 100%;
	width: 128px;
	z-index: 1000;
	-webkit-overflow-scrolling: touch;
	border-radius: 20px;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
	padding: 8px 10px;
	color: rgba(51, 51, 51, 1);
	font-weight: bold;
	text-transform: capitalize;
}

.Dropdown-option {
	box-sizing: border-box;
	color: rgba(51, 51, 51, 0.8);
	cursor: pointer;
	display: block;
	padding: 8px 10px;
}

.Dropdown-option:last-child {
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
	background-color: #f2f9fc;
	color: #333;
}

.Dropdown-option.is-selected {
	background-color: #f2f9fc;
	color: #333;
}

.Dropdown-noresults {
	box-sizing: border-box;
	color: #ccc;
	cursor: default;
	display: block;
	padding: 8px 10px;
}

.plantSpacer {
	display: none;
}

@media only screen and (max-height: 650px) {
	.plantContainer {
		overflow-y: scroll;
	}

	.plantSpacer {
		display: block;
		height: 20vh;
	}
}

@media only screen and (max-height: 800px) and (max-width: 1000px) {
	.plantContainer {
		overflow-y: scroll;
	}

	.plantSpacer {
		display: block;
		height: 20vh;
	}
	
}

@media only screen and (max-width: 1400px) {
	.plantContainer {
		padding-top: 70px;
	}

	.plantLeft {
		margin-right: 50px;
	}

  
}

@media only screen and (max-width: 1000px) {

	.plantLeft {
		margin-right: 0px;
	}

	.plantRight {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.plantGrid {
		margin-top: 0px;
		height: 800px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media only screen and (max-width: 600px) {
	.plantContainer {
		padding-top: 70px;
	}
	
	.plantGrid {
		margin-top: 89px;
		height: 400px;
	}

	.plantLeft {
		width: 300px;

	}

	.plantLeft {
		height: 250px;
		width: 300px;
		padding: 10px;
	}
	
	.plantTitle {
		font-size: 14px;
		margin-top: 6px;
		padding-left: 10px;
	}
	
	.levelSelector {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 40px;
		margin-top: 10px;
	}
	
	.selector {
		width: 25%;
		margin-left: auto;
		margin-right: auto;
	}
	
	.selectorButton {
		display: block;
		margin-top: auto;
		margin-bottom: auto;
		width: 30px;
		height: 18px;
		font-size: 14px;
	}
	
	.selectorButton:hover {
		cursor:pointer;
	}
	
	.level {
		width: 60%;
		margin-right: auto;
	}
	
	.levelText {
		font-size: 16px;
	}
	
	.levelDescription {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 0px;
		width: 100%;
		height: 25px;
	}

	.description{
		font-size: 12px;
	}
	
	.currencySelector {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 60px;
		margin-top: 10px;
	}
	
	.currency {
		width: 25%;
		margin: auto;
	}
	
	.currencyOption {
		width: 70px;
		height: fit-content;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}
	
	.currencyText {
		font-size: 14px;
		margin: 3px;
		text-align: center;
		color: #6d6b6b;
	}
	
	.currencyLogo {
		height: 20px;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 6px;
		margin-left: 10px;
	}
	
	.total {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 60%;
		margin-right: auto;
	}
	
	.totalText {
		margin-left: 40px;
		font-size: 16px;
	}
	
	.plantButton {
		width: 100%;
		height: 50px;
		margin-top: 10px;
		font-size: 16px;
	}

	.plantRight {
		height: 300px;
		width: 300px;
		background-color: #1D2D3A;
	}
	
	img {
		border-radius: 20px;
	}
	
	.video-player {
		border-radius: 20px;
		overflow: hidden;
	}
  
}