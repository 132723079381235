.navbar {
	height: 100px;
	display: flex;
	flex-direction: row;
}

.logo {
  text-decoration: none;
  height: 50px;
  padding-left: 50px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.landingTitle {
  font-size: 32px;
  font-weight: bold;
  color: #16242d;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.split {
  width: 3px;
}

.menu {
	margin-top: 56px;
	padding: 6px;
	position: absolute;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
	justify-self: center;
	background-color: white;
	border-radius: 10px;
	font-size: 18px;
	font-weight: 600;
	display: grid;
	grid-auto-flow: column;
  gap: 20px;
}

.spacer {
	width: 10px;
}

.navbar-Brand {
	height: 50px;
	margin-top: 30px;
}

.navbar-Link {
	margin-top: auto;
	margin-bottom: auto;
	text-decoration: none;
	padding: 12px;
	color: #6d6b6b;
}

.active-navbar-Link {
	border-radius: 14px;
	border-width: 4px;
	background-color: rgb(237, 238, 242);
	color: #16242d;
}

.address {
	margin-left: auto;
}

.connectWallet {
	margin-top: 32px;
	margin-right: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 44px;
	width: 170px;
	border-radius: 10px;
	border-color: transparent;
	background-color: #52b788;
	font-weight: 600;
}

.connectWallet:hover {
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
	border-color: #57cc99;
	transition: 0.5s;

}

.connectWalletText {
	font-size: 18px;
	font-family: Montserrat;
  color: white;
	margin-left: 6px;
}

.addressText {
	font-size: 16px;
	font-family: Montserrat;
  color: white;
	margin-left: 8px;
}

.addressContainer {
	margin-top: 32px;
	margin-right: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 44px;
	width: 170px;
	border-radius: 10px;
	border-color: transparent;
	background-color: #52b788;
	font-weight: 600;
}

sup {
	vertical-align: super;
	font-size: 10px;
}

@media only screen and (max-width: 900px) {
	.logo {
	  text-decoration: none;
	  height: 50px;
	  padding-left: 30px;
	  margin-top: 30px;
	  display: flex;
	  flex-direction: row;
	}
  
	.menu {
	  display: none;
	}

	.connectWalletMobile {
		margin-top: 32px;
		margin-right: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 44px;
		width: 100%;
		border-radius: 10px;
		border-color: transparent;
		background-color: #52b788;
		font-weight: 600;
	}
	
	.connectWalletMobile:hover {
		cursor: pointer;
		border-width: 1px;
		border-style: solid;
		border-color: #57cc99;
		transition: 0.5s;
	
	}

	.addressContainerMobile {
		margin-top: 32px;
		margin-right: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 44px;
		width: 100%;
		border-radius: 10px;
		border-color: transparent;
		background-color: #52b788;
		font-weight: 600;
	}

	.connectWallet {
		display: none;
	}

	.addressContainer {
		display: none;
	}
  
  }