/* Position and sizing of burger button */
.bm-burger-button {
    display: block;
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    transition: .4s;
    background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    text-decoration: none;
    display: inline-block;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
}

.bm-item:hover {
    color: #fff;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 900px) {
        /* Position and sizing of burger button */
    .bm-burger-button {
        display: none;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        display: none;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        display: none;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        display: none;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        display: none;
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
        display: none;
    }

    /* General sidebar styles */
    .bm-menu {
        display: none;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        display: none;
    }

    /* Wrapper for item list */
    .bm-item-list {
        display: none;
    }

    /* Individual item */
    .bm-item {
        display: none;
    }

    /* Styling of overlay */
    .bm-overlay {
        display: none;
    }
}